<template>
  <button
    class="language-button"
    @click.prevent="setLocale('en')"
  >
    <img
      class="language-icon"
      :src="languageIcon"
      alt="language-button"
    >
  </button>
</template>

<script>
import english from '../../assets/emoji/english.webp';
import french from '../../assets/emoji/french.webp';

export default {
  name: 'LanguageButton',

  data() {
    return {
      english,
      french,
    };
  },

  computed: {
    languageIcon() {
      if (this.$i18n.locale === 'fr') return english;
      return french;
    },
  },

  methods: {
    setLocale() {
      if (this.$i18n.locale === 'en') {
        localStorage.setItem('locale', 'fr');
        window.scrollTo(0, 0);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      } else if (this.$i18n.locale === 'fr') {
        localStorage.setItem('locale', 'en');
        window.scrollTo(0, 0);
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.language-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  margin-right: 10px;
}

.language-icon {
  width: 30px;
}
</style>
