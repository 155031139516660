<template>
  <div class="welcome-page">
    <Header />
    <WelcomePro v-if="isUserPro" />
    <WelcomePriv v-else />
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import WelcomePro from '../components/Welcome/WelcomePro.vue';
import WelcomePriv from '../components/Welcome/WelcomePriv.vue';

export default {
  name: 'Welcome',
  components: { Header, WelcomePro, WelcomePriv },

  computed: {
    isUserPro() {
      return this.$route.query.type === 'pro';
    },
  },
};
</script>

<style lang="scss" scoped>
  .welcome-page {
    height: 100vh;
  }
</style>
