/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      email: '',
      password: '',
      birthDate: '',
      ageRange: '',
      name: '',
    },
    establishment: {
      name: '',
      id: '',
      address: '',
      type: '',
      kbis: '',
      identity: '',
      city: '',
    },
  },
  mutations: {
    updateEstablishmentStep0(state, type) {
      state.establishment.type = type;
    },
    updateEstablishmentStep1(state, payload) {
      state.establishment.name = payload.name;
      state.establishment.id = payload.id;
    },
    updateCityStep1(state, payload) {
      state.establishment.city = payload.city;
    },
    updateEstablishmentStep2(state, payload) {
      state.establishment.name = payload.name;
      state.establishment.address = payload.address;
      state.establishment.id = payload.id;
    },
    updateEstablishmentStep3(state, payload) {
      state.establishment.identity = payload.identity;
      state.establishment.kbis = payload.kbis;
    },
  },
  actions: {
  },
  modules: {
  },
});
