<template>
  <div class="welcome-priv">
    <div class="text">
      <h1 class="title">
        {{ $t('welcomePriv.title') }}
      </h1>
      <p class="subtitle">
        {{ $t('welcomePriv.subtitle') }}
      </p>
    </div>
    <DownloadAppSection>
      {{ $t('welcomePriv.download') }}
    </DownloadAppSection>
  </div>
</template>

<script>
import DownloadAppSection from '../Sections/DownloadAppSection.vue';

export default {
  name: 'WelcomePriv',
  components: { DownloadAppSection },
};
</script>

<style lang="scss" scoped>
  .welcome-priv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 10px 0 10px;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    text-align: center;
    font-size: 50px;
  }

  .subtitle {
    text-align: center;
    width: 780px;
  }

  .download-app-section {
    margin: 100px 0 0 0;
  }

  @media (max-width: $mobileBreakpoint) {
    .subtitle {
      width: 300px;
    }
  }
</style>
