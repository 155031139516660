/* eslint-disable consistent-return */
const axios = require('axios');

function createFormData(fileList) {
  const formData = new FormData();
  if (!fileList.length) return;
  for (let i = 0; i < fileList.length; i += 1) {
    formData.append(`claim_creation[documents][${i}][documentFile][file]`, fileList[i]);
  }
  return formData;
}

export default async function createClaim(establishementId, files) {
  if (files.length !== 2) return { error: 'Missing files' };
  const body = createFormData(files);
  const res = await axios.post(`claims/${establishementId}`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((response) => ({ success: response.data }))
    .catch((e) => ({ error: e.response }));

  return res;
}
