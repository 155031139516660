<template>
  <div>
    <div
      class="header-container"
      data-aos="fade-in"
    >
      <div class="homepage-header">
        <img
          data-aos="fade-right"
          :src="logo"
          class="logo"
          alt="logo"
        >
        <CustomButton
          data-aos="fade-left"
          @click.native="goToSignup"
        >
          {{ $t('homepage.header.register') }}
        </CustomButton>
      </div>
    </div>
    <RegisterModal
      v-if="showModal"
      :close="closeModal"
    />
  </div>
</template>

<script>
import CustomButton from '../Buttons/CustomButton.vue';
import logo from '../../assets/logo.svg';
import RegisterModal from '../Modal/RegisterModal.vue';

export default {
  name: 'HomepageHeader',
  components: { CustomButton, RegisterModal },

  data() {
    return {
      logo,
      showModal: false,
    };
  },

  methods: {
    goToSignup() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
  .homepage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    background-color: white;
    height: 70px;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.2);
    position: sticky;
    top: 0px;
    z-index: 10;

    // &:hover {
    //   transform: translateY(7px);
    //   box-shadow: none;
    //   transition: transform 0.2s, box-shadow 0.2s, background 0.1s;
    // }
  }

  .logo {
    width: 100px;
  }

  .header-container {
    background-color: white;
  }

  @media (max-width: $mobileBreakpoint) {
    .homepage-header {
      padding: 0 10px;
    }
  }
</style>
