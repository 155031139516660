<template>
  <div class="mood-card">
    <div
      class="icon-container picto-color"
      :class="pictoColor"
    >
      <img
        :src="picto"
        class="picto"
        alt="mood-picto"
      >
    </div>
    <h2>{{ text }}</h2>
  </div>
</template>

<script>
export default {
  name: 'MoodCard',

  props: {
    text: {
      type: String,
      required: true,
    },
    picto: {
      type: String,
      required: true,
    },
    pictoColor: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
  .mood-card {
    width: 180px;
    height: 210px;
    box-shadow: 0px 0px 42px 0px rgba(219,219,219,0.42);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items:  center;
    justify-content: center;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    width: 80px;
    height: 80px;
    border-radius: 15px;
    margin-bottom: 30px;
  }

  .picto-color.yellow {
    background-color: $secondary1;
  }

  .picto-color.purple {
    background-color: $secondary2;
  }

  .picto-color.pink {
    background-color: $secondary3;
  }

  .picto {
    width: 50px;
  }

  @media (max-width: $mobileBreakpoint) {
    .mood-card {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
</style>
