<template>
  <div>
    <div class="input-container">
      <label>{{ label }}</label>
      <input
        :id="inputId"
        class="upload-input"
        name="pdf_upload"
        type="file"
        accept=".pdf,image"
        @change="inputChanged"
      >
      <label
        class="file-input"
        :for="inputId"
      >
        {{ fileName }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileInput',

  props: {
    label: {
      required: true,
      type: String,
    },
    inputId: {
      required: true,
      type: String,
    },
    returnDataFunction: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      fileName: this.$t('form.placeholders.fileInput'),
    };
  },

  methods: {
    inputChanged(e) {
      this.fileName = e.target.files[0].name;
      this.returnDataFunction(e.target.files[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-label {
  font-size: 12px;
  color: #4F4F4F;
}

.file-input {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 5px;
  height: 40px;
  width: 300px;
  border: 1px solid white;
  border-radius: 6px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.10);
  padding: 0 10px;
  font-family: $textFont;
  font-size: 14px;
  &:focus {
    border: 2px solid $primary;
    outline: none;
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  height: 75px;
  width: 320px;
}

@media (max-width: $mobileBreakpoint) {
    .file-input {
      width: 200px;
    }

    .input-container {
      width: 220px;
    }
  }
</style>
