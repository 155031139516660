<template>
  <div class="download-app-section">
    <div class="background">
      <div
        class="left-content"
        data-aos="fade-right"
      >
        <h1 class="title">
          {{ $t('homepage.download.download') }}
        </h1>
        <p class="subtitle">
          {{ $t('homepage.download.subtitle1') }}
          <br>
          {{ $t('homepage.download.subtitle2') }}
        </p>

        <StoreButtons />
      </div>
      <img
        class="homepage-app"
        src="../../assets/homepage-image.png"
        data-aos="fade-left"
        alt="homepage-app-image"
      >
    </div>
  </div>
</template>

<script>
import StoreButtons from '../Cards/StoreButtons.vue';

export default {
  name: 'DownloadAppSectionVue',
  components: { StoreButtons },
};
</script>

<style scoped lang="scss">
  .download-app-section {
    display: flex;
    justify-content: center;
    margin: 180px 0 180px 0;
  }
  .background {
    position: relative;
    display:flex;
    flex-direction: column;
    justify-content: center;
    background-color: $primary;
    border-radius: 20px;
    height: 480px;
    width: 70%
  }

  .title {
    width: 800px;
    line-height: 55px;
    font-size: 45px;
    color: white !important;
  }

  .subtitle {
    width:380px;
    line-height: 28px;
    color: white;
  }

  .homepage-app {
    position: absolute;
    width: 490px;
    top: -90px;
    right: 40px;
  }

  .left-content {
    margin-left: 150px;
  }

@media (max-width: 1375px) {
  .homepage-app {
    display: none;
  }

  .download-app-section {
    margin: 50px 0 50px 0;
  }

  .background {
    height: 400px;
    width: 90%;
    // padding: 20px;
    align-items: center;
  }

  .title {
    width: 270px;
    line-height: 30px;
    font-size: 28px;
    text-align: center;
  }

  .subtitle {
    width: 250px;
    text-align: center;
  }

  .left-content {
    margin-left: 0;
    display:flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
