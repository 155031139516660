<template>
  <div class="claim-step3">
    <h1 class="title-modal">
      {{ $t('claimModal.title') }}
    </h1>
    <p
      class="subtitle"
    >
      {{ $t('claimModal.step3.subtitle') }}
    </p>
    <FileInput
      :label="$t('claimModal.step3.identityLabel')"
      input-id="identity"
      :return-data-function="processIdentity"
    />
    <FileInput
      :label="$t('claimModal.step3.kbisLabel')"
      input-id="kbis"
      :return-data-function="processKbis"
    />
    <CustomButton
      class="button"
      @click.native="checkValidateButton()"
    >
      {{ $t('claimModal.validate') }}
    </CustomButton>
  </div>
</template>

<script>
import FileInput from '../Forms/FileInput.vue';
import CustomButton from '../Buttons/CustomButton.vue';
import Store from '../../store/index';
import createClaim from '../../services/Claim/createClaim';

export default {
  name: 'ClaimStep3',
  components: { FileInput, CustomButton },

  props: {
    goToNextStep: {
      type: Function,
      required: true,
    },
    setError: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      files: {
        identity: null,
        kbis: null,
      },
    };
  },

  methods: {
    processIdentity(file) {
      this.files.identity = file;
    },

    processKbis(file) {
      this.files.kbis = file;
    },

    async checkValidateButton() {
      this.setError('');
      if (!this.files.identity || !this.files.kbis) {
        this.setError(this.$t('form.error.mandatoryFields'));
        return;
      }
      Store.commit('updateEstablishmentStep3', this.files);
      await createClaim(Store.state.establishment.id, this.files);
      this.goToNextStep();
    },
  },
};
</script>

<style lang="scss" scoped>
  .title-modal {
    text-align: center;
  }

  .button {
    width: 70%;
    margin-top: 20px;
  }

  .claim-step3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subtitle {
    margin: 0 0 40px 0!important;
    width: 400px;
    text-align: center;
  }

  @media (max-width: $mobileBreakpoint) {
    .title-modal {
      font-size: 30px;
      line-height: 1.2;
    }

    .subtitle {
      font-size: 14px;
      margin-top: 5px;
      width: 240px;
    }
  }
</style>
