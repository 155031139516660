<template>
  <div>
    <div
      class="header-container"
      data-aos="fade-in"
    >
      <div class="header">
        <img
          data-aos="fade-right"
          :src="logo"
          class="logo"
          alt="logo"
          @click.native="goToHomepage()"
        >
      </div>
    </div>
  </div>
</template>

<script>
import logo from '../assets/logo.svg';

export default {
  name: 'Header',

  data() {
    return {
      logo,
    };
  },

  methods: {
    goToHomepage() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  background-color: white;
  height: 70px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0px;
  z-index: 10;
}

.logo {
  width: 100px;
  cursor: pointer;
}

.header-container {
  background-color: white;
}

@media (max-width: $mobileBreakpoint) {
  .header {
    padding: 0 10px;
  }
}
</style>
