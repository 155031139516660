<template>
  <div class="concept-card">
    <div
      class="picto-container background-color"
      :class="backgroundColor"
    >
      <img
        class="picto"
        :src="picto"
        alt="concept-picto"
      >
    </div>
    <h2 class="title">
      <slot name="title" />
    </h2>
    <p class="description">
      <slot name="description" />
    </p>
  </div>
</template>

<script>
export default {
  name: 'ConceptCard',

  props: {
    picto: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.concept-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 330px;
}

.description {
  text-align: center;
  width: 280px;
}

.picto-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 22px;
  margin-bottom: 20px;
  box-shadow: 0px 40px 70px 0px #112e391f;
}

.background-color.yellow {
  background-color: $secondary1;
}

.background-color.purple {
  background-color: $secondary2;
}

.background-color.pink {
  background-color: $secondary3;
}

.picto {
  width: 50%;
}

.title {
  font-size: 18px;
}

@media (max-width: $mobileBreakpoint) {
  .concept-card {
    width: 200px;
  }

  .description {
    width: 280px;
    margin: 5px 0 20px 0;
  }

  .picto-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 15px;
    margin-bottom: 10px;
  }

  .title {
    text-align: center;
    font-size: 16px;
  }
}
</style>
