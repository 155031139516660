<template>
  <div>
    <div class="welcome-pro">
      <div class="text">
        <h1 class="title">
          {{ $t('welcomePro.title') }}
        </h1>
        <p class="subtitle">
          {{ $t('welcomePro.subtitle') }}
        </p>
        <CustomButton @click.native="openModal">
          J'inscris mon établissement
        </CustomButton>
      </div>
      <DownloadAppSection>
        {{ $t('welcomePro.download') }}
      </DownloadAppSection>
    </div>
    <ClaimModal
      v-if="showModal"
      :close="closeModal"
    />
  </div>
</template>

<script>
import DownloadAppSection from '../Sections/DownloadAppSection.vue';
import CustomButton from '../Buttons/CustomButton.vue';
import ClaimModal from '../Modal/ClaimModal.vue';

export default {
  name: 'WelcomePro',
  components: { DownloadAppSection, CustomButton, ClaimModal },

  data() {
    return {
      showModal: false,
    };
  },

  methods: {
    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .welcome-pro {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 10px 0 10px;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    text-align: center;
    font-size: 50px;
  }

  .subtitle {
    text-align: center;
    width: 780px;
  }

  .download-app-section {
    margin: 100px 0 0 0;
  }

  @media (max-width: $mobileBreakpoint) {
    .subtitle {
      width: 300px;
    }
  }
</style>
