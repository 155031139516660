<template>
  <div class="claim-step0">
    <h1 class="title-modal">
      {{ $t('claimModal.title') }}
    </h1>
    <p class="subtitle">
      {{ $t('claimModal.step0.subtitle') }}
    </p>
    <div class="concept-cards">
      <ConceptCard
        background-color="purple"
        :picto="nightclub"
        class="card"
        @click.native="typeFunction('night_club')"
      >
        <template #title>
          {{ $t('claimModal.step0.nightClub') }}
        </template>
      </ConceptCard>
      <ConceptCard
        background-color="yellow"
        :picto="bar"
        class="card"
        @click.native="typeFunction('bar')"
      >
        <template #title>
          {{ $t('claimModal.step0.bar') }}
        </template>
      </ConceptCard>
      <ConceptCard
        background-color="pink"
        :picto="restaurant"
        class="card"
        @click.native="typeFunction('restaurant')"
      >
        <template #title>
          {{ $t('claimModal.step0.restaurant') }}
        </template>
      </ConceptCard>
    </div>
  </div>
</template>

<script>
import ConceptCard from '../Cards/ConceptCard.vue';
import bar from '../../assets/icons/bar.svg';
import restaurant from '../../assets/icons/restaurant.svg';
import nightclub from '../../assets/icons/nightclub.svg';
import Store from '../../store/index';

export default {
  name: 'ClaimStep0',
  components: { ConceptCard },

  props: {
    redirectFunction: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      bar,
      restaurant,
      nightclub,
    };
  },

  methods: {
    typeFunction(type) {
      Store.commit('updateEstablishmentStep0', type);
      this.redirectFunction();
    },
  },
};
</script>

<style lang="scss" scoped>
  .title-modal {
    text-align: center;
  }

  .subtitle {
    margin: 0 0 50px 0!important;
  }

  .button {
    margin-top: 10px;
    width: 80%;
  }

  .claim-step0 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .concept-cards {
    display: flex;
  }

  .card {
    width: 170px;
    cursor: pointer;
  }

  .signin-link {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }

  @media (max-width: $mobileBreakpoint) {
    .title-modal {
      line-height: 1.2;
      font-size: 30px;
    }

    .subtitle {
      margin-top: 5px;
      font-size: 14px;
    }

    .concept-cards {
      display: flex;
      width: 250px;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
>
