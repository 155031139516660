<template>
  <div class="contact-picto">
    <img
      :src="picto"
      class="picto"
      alt="contact-picto"
    >
    <a
      v-if="type === 'email'"
      class="text"
      href="mailto:contact@beb-r.app"
    >
      {{ text }}
    </a>
    <p v-else>
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ContactPicto',

  props: {
    picto: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.contact-picto {
  display: flex;
  align-items: center;
}

.picto {
  width: 20px;
  margin-right: 10px;
}

.text {
  text-decoration: none;
  color: $textColor;
}

@media (max-width: $mobileBreakpoint) {
  .contact-picto {
    flex-direction: column;
    align-items: center;
  }
}
</style>
