<template>
  <div class="join-section">
    <h1 class="title">
      {{ $t('homepage.joinUs.title1') }}
      <br>
      {{ $t('homepage.joinUs.title2') }}
    </h1>
    <p class="subtitle">
      {{ $t('homepage.joinUs.subtitle') }}
    </p>
    <div
      class="pro-benefits"
      data-aos="fade-down"
    >
      <ProBenefit
        v-for="(item) in proBenefits"
        :key="item.pictoColor"
        :picto-color="item.pictoColor"
        :text="item.text"
      />
    </div>
    <CustomButton
      data-aos="fade-left"
      @click.native="goToSignup"
    >
      {{ $t('homepage.joinUs.registerEstablishment') }}
    </CustomButton>
    <RegisterModal
      v-if="showModal"
      :close="closeModal"
    />
  </div>
</template>

<script>
import RegisterModal from '../Modal/RegisterModal.vue';
import CustomButton from '../Buttons/CustomButton.vue';
import ProBenefit from '../ProBenefit.vue';

export default {
  name: 'JoinSection',
  components: { ProBenefit, CustomButton, RegisterModal },

  data() {
    return {
      proBenefits: [
        {
          pictoColor: 'yellow',
          text: this.$t('homepage.joinUs.proBenefits.yellow'),
        },
        {
          pictoColor: 'pink',
          text: this.$t('homepage.joinUs.proBenefits.pink'),
        },
        {
          pictoColor: 'purple',
          text: this.$t('homepage.joinUs.proBenefits.purple'),
        },
      ],
      showModal: false,
    };
  },

  methods: {
    goToSignup() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.join-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.title {
  width: 800px;
  line-height: 55px;
  font-size: 45px;
  text-align: center;
}

.subtitle {
  width: 600px;
  text-align: center;
}

.pro-benefits {
  display: flex;
  margin-bottom: 20px;
}

@media (max-width: $mobileBreakpoint) {
  .title {
    width: 270px;
    line-height: 30px;
    font-size: 28px;
  }

  .subtitle {
    width: 320px;
  }

  .pro-benefits {
    flex-direction: column;
  }
}
</style>
