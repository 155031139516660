<template>
  <div class="moods-section">
    <h1 class="title">
      {{ $t('homepage.moods.title') }}
    </h1>
    <div
      class="moods-list"
      data-aos="fade-zoom-in"
    >
      <MoodCard
        v-for="(item) in moods"
        :key="item.text"
        :text="item.text"
        :picto-color="item.pictoColor"
        :picto="item.picto"
        class="mood-cards"
      />
    </div>
  </div>
</template>

<script>
import MoodCard from '../Cards/MoodCard.vue';
import festif from '../../assets/emoji/festif.webp';
import dansant from '../../assets/emoji/dansant.webp';
import romantique from '../../assets/emoji/romantique.webp';
import entreAmis from '../../assets/emoji/entre-amis.webp';
import enFamille from '../../assets/emoji/en-famille.webp';

export default {
  name: 'MoodsSection',
  components: { MoodCard },

  data() {
    return {
      moods: [
        {
          text: this.$t('homepage.moods.dancing'),
          picto: dansant,
          pictoColor: 'yellow',
        },
        {
          text: this.$t('homepage.moods.friends'),
          picto: entreAmis,
          pictoColor: 'pink',
        },
        {
          text: this.$t('homepage.moods.festive'),
          picto: festif,
          pictoColor: 'purple',
        },
        {
          text: this.$t('homepage.moods.family'),
          picto: enFamille,
          pictoColor: 'pink',
        },
        {
          text: this.$t('homepage.moods.romantic'),
          picto: romantique,
          pictoColor: 'yellow',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
  .moods-section {
    margin: 100px 0 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    width: 600px;
    line-height: 55px;
    text-align: center;
    font-size: 45px;
    padding-bottom: 40px;
  }

  .moods-list {
    display: flex;
  }

  .mood-cards {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media (max-width: $mobileBreakpoint) {
    .title {
      width: 270px;
      line-height: 30px;
      font-size: 28px;
    }

    .moods-section {
      margin: 50px 0 50px 0;
    }

    .moods-list {
      flex-direction: column;
    }

    .mood-cards {
      margin-right: 0;
    }
  }
</style>
