<template>
  <div class="select-container">
    <label>{{ label }}</label>
    <select
      id="pet-select"
      name="pets"
      @input="updateValue"
    >
      <option value="">
        {{ $t('component.select.title') }}
      </option>
      <option
        v-for="(option, index) in data"
        :key="index"
        :value="option.value"
      >
        {{ option.display }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'Select',

  props: {
    label: {
      required: true,
      type: String,
    },
    data: {
      required: true,
      type: Array,
    },
  },

  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .select-container {
    display: flex;
    flex-direction: column;
    height: 75px;
    width: 320px;
  }

  label {
    font-size: 12px;
    color: #4F4F4F;
  }

  select {
    margin-top: 5px;
    height: 43px;
    width: 100%;
    border: 1px solid white;
    border-radius: 6px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.10);
    padding: 0 10px;
    font-family: $textFont;
    &:focus {
      border: 2px solid $primary;
      outline: none;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    .select-container {
      width: 220px;
    }
  }
</style>
