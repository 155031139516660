import Vue from 'vue';
import AOS from 'aos';
import VueMapbox from '@studiometa/vue-mapbox-gl';
import store from './store';
import router from './router';
import App from './App.vue';
import 'aos/dist/aos.css';
import i18n from './i18n';
import 'mapbox-gl/dist/mapbox-gl.css';

const axios = require('axios');

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

Vue.use(VueMapbox);

Vue.config.productionTip = false;
new Vue({
  created() {
    AOS.init();
  },

  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
