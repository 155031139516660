<template>
  <div class="footer">
    <div class="footer-content">
      <img
        :src="logo"
        class="logo"
        alt="logo"
      >
      <div class="contact-pictos-placement">
        <LanguageButton />
        <ContactPicto
          :picto="emailPicto"
          text="contact@beb-r.app"
          type="email"
          class="spacing-right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContactPicto from '../ContactPicto.vue';
import LanguageButton from '../Buttons/LanguageButton.vue';
import emailPicto from '../../assets/icons/email.svg';
import logo from '../../assets/logo.svg';

export default {
  name: 'Footer',
  components: { ContactPicto, LanguageButton },
  data() {
    return {
      emailPicto,
      logo,
    };
  },
};
</script>

<style scoped lang="scss">
.footer {
  background-color: rgba(17, 138, 178, 0.3);
  height: 100px;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 50px;
  height: 100%;
}

.separator {
  border: 1px solid $primary;
}

.contact-pictos-placement {
  display: flex;
  justify-self: flex-end;
}

.spacing-right {
  margin-right: 30px;
}

.logo {
  width: 100px;
}

@media (max-width: $mobileBreakpoint) {
  .footer {
    height: 150px;
    padding-top: 0;
  }

  .footer-content {
    align-items: center;
    padding: 0 20px;
  }
}
</style>
