<template>
  <Modal
    :close="close"
    :back="back"
    :display-back="displayBack"
  >
    <ClaimStep0
      v-if="step === 0"
      :redirect-function="setNextStep"
    />
    <ClaimStep1
      v-if="step === 1"
      :go-to-step2="setNextStep"
      :go-to-step3="goToStep3"
      :set-error="setErrorMessage"
    />
    <ClaimStep2
      v-if="step === 2"
      :go-to-next-step="setNextStep"
      :set-error="setErrorMessage"
    />
    <ClaimStep3
      v-if="step === 3"
      :go-to-next-step="validateForm"
      :set-error="setErrorMessage"
    />
    <ClaimStep4
      v-if="step === 4"
      :close="close"
    />
    <span class="error-text">{{ textError }}</span>
  </Modal>
</template>

<script>
import Modal from './Modal.vue';
import ClaimStep0 from '../ClaimFlow/ClaimStep0.vue';
import ClaimStep1 from '../ClaimFlow/ClaimStep1.vue';
import ClaimStep2 from '../ClaimFlow/ClaimStep2.vue';
import ClaimStep3 from '../ClaimFlow/ClaimStep3.vue';
import ClaimStep4 from '../ClaimFlow/ClaimStep4.vue';
import Store from '../../store/index';
import createClaim from '../../services/Claim/createClaim';

export default {
  name: 'ClaimModal',
  components: {
    Modal,
    ClaimStep1,
    ClaimStep0,
    ClaimStep2,
    ClaimStep3,
    ClaimStep4,
  },

  props: {
    close: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      step: 0,
      previousStep: 0,
      textError: '',
    };
  },

  computed: {
    displayBack() {
      return this.step !== 0 && this.step !== 4;
    },
  },

  methods: {
    setNextStep() {
      this.previousStep = this.step;
      this.step += 1;
    },

    back() {
      this.step = this.previousStep;
      this.previousStep -= 1;
      this.textError = '';
    },

    goToStep3() {
      this.previousStep = this.step;
      this.step += 2;
    },

    validateForm() {
      const { establishment } = Store.state;
      createClaim(establishment.id, [establishment.kbis, establishment.identity]);
      this.setNextStep();
    },

    setErrorMessage(message) {
      this.textError = message;
    },
  },
};
</script>

<style lang="scss">
.error-text {
  font-size: 12px;
  color: red;
  padding-top: 20px;
  text-align: center;
  width: 100%;
  display: block;
}
</style>
