<template>
  <div>
    <img
      src="../../assets/apple-store-badge.svg"
      class="apple-store-button"
      alt="apple-store-button"
    >
  </div>
</template>

<script>
export default {
  name: 'AppleStoreButton',
};
</script>

<style lang="scss" scoped>
 .apple-store-button {
   width: 170px;
   padding-top: 12px;
  @media (max-width: $mobileBreakpoint) {
    width: 120px;
    padding-top: 8px;
  }
 }
</style>
