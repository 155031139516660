<template>
  <div
    class="modal-background"
    data-aos="fade-in"
  >
    <div class="modal-content">
      <img
        :src="closeIcon"
        class="close"
        alt="close-icon"
        @click.prevent="close()"
      >
      <img
        v-if="displayBack"
        :src="backIcon"
        class="back"
        alt="back-icon"
        @click.prevent="back()"
      >
      <slot />
    </div>
  </div>
</template>

<script>
import closeIcon from '../../assets/icons/close.svg';
import backIcon from '../../assets/icons/back.svg';

export default {
  name: 'Modal',

  props: {
    close: {
      required: true,
      type: Function,
    },
    back: {
      default: () => {},
      type: Function,
    },
    displayBack: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      closeIcon,
      backIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
  .modal-background {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: auto;
    top: 0px;
    z-index: 40;
  }
  .modal-content {
    padding: 50px 100px;
    background-color: white;
    border-radius: 25px;
    position: relative;

  }
  .close {
    cursor: pointer;
    width: 20px;
    position: absolute;
    top: 15px;
    right: 20px;
  }
  .back {
    cursor: pointer;
    width: 15px;
    position: absolute;
    top: 20px;
    left: 20px;
  }
   @media (max-width: $mobileBreakpoint) {
    .modal-content {
      max-width: 240px;
      padding: 30px 40px;
    }
  }
</style>
