<template>
  <div class="step3">
    <h1 class="title-modal">
      {{ $t('modal.register.step3.title') }}
    </h1>
    <p
      v-if="userType === 'pro'"
      class="subtitle"
    >
      {{ $t('modal.register.step3.subtitleProfessional') }}
    </p>
    <p
      v-if="userType === 'priv'"
      class="subtitle"
    >
      {{ $t('modal.register.step3.subtitlePrivate') }}
    </p>
    <Input
      v-model="name"
      type="text"
      :label="this.$t('modal.register.step3.form.pseudo')"
      :value="name"
    />
    <Input
      v-if="isBirthDate"
      v-model="age"
      type="date"
      :label="this.$t('modal.register.step3.form.birthDate')"
    />
    <Select
      v-if="!isBirthDate"
      v-model="ageRange"
      :label="this.$t('modal.register.step3.form.ageRange')"
      :data="ageRangeData"
    />
    <span
      v-if="isBirthDate && userType === 'priv'"
      class="text-link"
      @click.prevent="changeAge()"
    >{{ $t('modal.register.step3.form.ageRangeChoice') }}</span>
    <span
      v-if="!isBirthDate && userType === 'priv'"
      class="text-link"
      @click.prevent="changeAge()"
    >{{ $t('modal.register.step3.form.birthDateChoice') }}</span>
    <CustomButton
      class="button"
      @click.native="validateForm()"
    >
      {{ $t('modal.register.step3.form.submit') }}
    </CustomButton>
  </div>
</template>

<script>
import Input from '../Forms/Input.vue';
import Select from '../Forms/Select.vue';
import CustomButton from '../Buttons/CustomButton.vue';

export default {
  name: 'Step3',
  components: { Input, CustomButton, Select },

  props: {
    userType: {
      required: true,
      type: String,
    },
    validateFunction: {
      required: true,
      type: Function,
    },
    setError: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      age: '',
      name: '',
      ageRange: '',
      isBirthDate: true,
      ageRangeData: [
        {
          value: '18-25',
          display: this.$t('modal.register.step3.form.range.18to25'),
        },
        {
          value: '26-40',
          display: this.$t('modal.register.step3.form.range.26to40'),
        },
        {
          value: '41-55',
          display: this.$t('modal.register.step3.form.range.41to55'),
        },
        {
          value: '56+',
          display: this.$t('modal.register.step3.form.range.56more'),
        },
      ],
    };
  },

  methods: {
    changeAge() {
      this.age = '';
      this.ageRange = '';
      this.isBirthDate = !this.isBirthDate;
    },

    validateForm() {
      this.setError('');

      if (this.name === '' || (this.ageRange === '' && this.age === '')) {
        this.setError(this.$t('form.error.mandatoryFields'));
        return;
      }

      this.validateFunction(this.name, this.age, this.ageRange);
    },
  },
};
</script>

<style lang="scss" scoped>
  .title-modal {
    text-align: center;
  }

  .button {
    margin-top: 20px;
    width: 80%;
  }

  .step3 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subtitle {
    margin: 0 0 40px 0!important;
    width: 400px;
    text-align: center;
  }

  .text-link {
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
  }

  @media (max-width: $mobileBreakpoint) {
    .title-modal {
      font-size: 30px;
    }

    .subtitle {
      font-size: 14px;
      width: 240px;
    }
  }
</style>
