<template>
  <div>
    <Input
      v-model="inputContent"
      :label="label"
      type="text"
      :input-value="inputValue"
      :backspace-function="setNotSelected"
      :disabled="disabled"
    />
    <div class="proposition">
      <ul>
        <li
          v-for="(proposition, index) in propositions"
          :key="index"
          @click="selectAnswer(proposition.name)"
        >
          {{ proposition.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Input from './Input.vue';

export default {
  name: 'Autocomplete',
  components: { Input },

  props: {
    label: {
      required: true,
      type: String,
    },
    searchData: {
      required: true,
      type: Array,
    },
    returnDataFunction: {
      required: true,
      type: Function,
    },
    defaultInputContent: {
      default: '',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      inputContent: '',
      inputValue: '',
      propositions: [],
      selected: false,
    };
  },

  watch: {
    inputContent() {
      if (this.inputContent && !this.selected) this.propositions = this.searchInData();
      if (this.inputContent === '') this.propositions = [];
      if (this.inputValue && this.selected) this.returnDataFunction(this.inputValue);
    },
  },

  mounted() {
    if (this.defaultInputContent) this.selected = true;
    this.inputContent = this.defaultInputContent;
    this.inputValue = this.defaultInputContent;
  },

  methods: {
    searchInData() {
      const resArray = [];
      for (let i = 0; i < this.searchData.length; i += 1) {
        const searchName = this.searchData[i].name.toLowerCase();
        if (searchName.includes(this.inputContent)) {
          resArray.push(this.searchData[i]);
        }
      }
      if (resArray.length > 3) resArray.length = 3;
      return resArray;
    },

    selectAnswer(answer) {
      this.inputValue = answer;
      this.inputContent = answer;
      this.selected = true;
      this.propositions = [];
    },

    setNotSelected() {
      this.selected = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.proposition {
  padding: 0;
  margin: 0;
  border-radius: 6px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.10);
  position: absolute;
  left: 0;
  right: 0;
  ul {
    background-color: white;
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
    padding: 10px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.15);
      cursor: pointer;
    }
  }

}

.input-container {
  height: unset;
}

.text-link {
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
  }
</style>
