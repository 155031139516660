/* eslint-disable consistent-return */
const axios = require('axios');

export default async function createEstablishment(establishmentData) {
  const res = await axios.post('establishments/', establishmentData, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => ({ success: response.data }))
    .catch((e) => ({ error: e.response }));

  return res;
}
