<template>
  <div class="store-buttons">
    <AppleStoreButton />
    <PlayStoreButton />
  </div>
</template>

<script>
import AppleStoreButton from '../Buttons/AppleStoreButton.vue';
import PlayStoreButton from '../Buttons/PlayStoreButton.vue';

export default {
  name: 'StoreButtons',
  components: { AppleStoreButton, PlayStoreButton },
};
</script>

<style lang="scss" scoped>
  .store-buttons {
    display: flex;
  }
</style>
