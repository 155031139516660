<template>
  <Modal
    :close="close"
    :back="back"
    :display-back="displayBack"
  >
    <Login
      v-if="step === '0'"
      :redirect-function="setSignup"
    />
    <Step1
      v-if="step === '1'"
      :pro-function="setProUser"
      :priv-function="setPrivUser"
      :redirect-function="setSignin"
    />
    <Step2
      v-if="step === '2'"
      :user-type="user.type"
      :add-data-function="addEmailData"
      :set-error="setErrorMessage"
      :user-email="user.email"
      :user-password="user.password"
    />
    <Step3
      v-if="step === '3'"
      :user-type="user.type"
      :validate-function="addPersonnalData"
      :set-error="setErrorMessage"
    />
    <span class="error-text">{{ textError }}</span>
  </Modal>
</template>

<script>
import Login from '../SignupFlow/Login.vue';
import login from '../../services/Security/login';
import register from '../../services/Security/register';
import Modal from './Modal.vue';
import Step1 from '../SignupFlow/Step1.vue';
import Step2 from '../SignupFlow/Step2.vue';
import Step3 from '../SignupFlow/Step3.vue';

export default {
  name: 'RegisterModal',
  components: {
    Modal, Step1, Step2, Step3, Login,
  },

  props: {
    close: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      step: '1',
      user: {
        type: '',
        email: '',
        password: '',
        name: '',
        birthDate: '',
        ageRange: '',
      },
      textError: '',
    };
  },

  computed: {
    displayBack() {
      return this.step === '2' || this.step === '3';
    },
  },

  methods: {
    back() {
      if (this.step === '2') this.step = '1';
      if (this.step === '3') this.step = '2';
    },

    setProUser() {
      this.user.type = 'pro';
      this.step = '2';
    },

    setPrivUser() {
      this.user.type = 'priv';
      this.step = '2';
    },

    setSignin() {
      this.step = '0';
    },

    setSignup() {
      this.step = '1';
    },

    addEmailData(email, password) {
      this.user.email = email;
      this.user.password = password;
      this.step = '3';
    },

    async addPersonnalData(name, birthDate = '', ageRange = '') {
      this.user.name = name;

      if (birthDate) {
        this.user.birthDate = birthDate;
      }

      if (ageRange) {
        this.user.ageRange = ageRange;
      }

      const registerRes = await register(this.user);

      if (registerRes.error?.status === 409) {
        this.textError = registerRes.error.data.error;
        return;
      }

      if (!registerRes.user) {
        this.textError = this.$t('form.error.error');
        return;
      }

      const loginRes = await login(this.user.email, this.user.password);

      if (loginRes.error) {
        this.setErrorMessage(loginRes.error.data.message);
        return;
      }
      await this.$router.push(`/welcome?type=${this.user.type}`);
    },

    setErrorMessage(message) {
      this.textError = message;
    },
  },
};
</script>

<style lang="scss">
.error-text {
  font-size: 12px;
  color: red;
  padding-top: 20px;
  text-align: center;
  width: 100%;
  display: block;
}
</style>
