<template>
  <div class="step1">
    <h1 class="title-modal">
      {{ $t('modal.register.step1.title') }}
    </h1>
    <p class="subtitle">
      {{ $t('modal.register.step1.subtitle') }}
    </p>
    <div class="concept-cards">
      <ConceptCard
        background-color="yellow"
        :picto="happy"
        class="card"
        @click.native="privFunction"
      >
        <template #title>
          {{ $t('modal.register.step1.individual') }}
        </template>
      </ConceptCard>
      <ConceptCard
        background-color="purple"
        :picto="pro"
        class="card"
        @click.native="proFunction"
      >
        <template #title>
          {{ $t('modal.register.step1.professional') }}
        </template>
      </ConceptCard>
    </div>
    <span
      class="signin-link"
      @click.prevent="redirectFunction"
    >
      {{ $t('modal.register.step1.alreadyHaveAnAccount') }}
    </span>
  </div>
</template>

<script>
import ConceptCard from '../Cards/ConceptCard.vue';
import pro from '../../assets/icons/pro.svg';
import happy from '../../assets/icons/happy.svg';

export default {
  name: 'Step1',
  components: { ConceptCard },

  props: {
    proFunction: {
      type: Function,
      required: true,
    },
    privFunction: {
      type: Function,
      required: true,
    },
    redirectFunction: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      pro,
      happy,
    };
  },
};
</script>

<style lang="scss" scoped>
  .title-modal {
    text-align: center;
  }

  .subtitle {
    margin: 0 0 40px 0!important;
  }

  .button {
    margin-top: 10px;
    width: 80%;
  }

  .step1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .concept-cards {
    display: flex;
  }

  .card {
    width: 170px;
    cursor: pointer;
  }

  .signin-link {
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }

  @media (max-width: $mobileBreakpoint) {
    .title-modal {
      font-size: 30px;
    }

    .subtitle {
      font-size: 14px;
    }

    .card {
      width: 140px;
    }
  }
</style>
