<template>
  <div class="input-container">
    <label>{{ label }}</label>
    <input
      v-model="value"
      :type="type"
      :disabled="disabled"
      @input="updateValue"
      @keyup.delete="backspaceFunction"
    >
  </div>
</template>

<script>
export default {
  name: 'Input',

  props: {
    label: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    inputValue: {
      default: '',
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    backspaceFunction: {
      default: () => {},
      type: Function,
    },
  },

  data() {
    return {
      value: '',
    };
  },

  watch: {
    inputValue() {
      this.value = this.inputValue;
    },
  },

  mounted() {
    this.value = this.inputValue;
  },

  methods: {
    updateValue(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .input-container {
    display: flex;
    flex-direction: column;
    height: 75px;
    width: 320px;
  }

  label {
    font-size: 12px;
    color: #4F4F4F;
  }

  input {
    margin-top: 5px;
    height: 40px;
    width: 300px;
    border: 1px solid white;
    border-radius: 6px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.10);
    padding: 0 10px;
    font-family: $textFont;
    &:focus {
      border: 2px solid $primary;
      outline: none;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    input {
      width: 200px;
    }

    .input-container {
      width: 220px;
    }
  }
</style>
