<template>
  <div class="map-section">
    <h1 class="title">
      {{ $t('homepage.map.title') }}
    </h1>
    <MglMap
      :access-token="accessToken"
      :map-style="mapStyle"
      class="map"
      :center="[0,0]"
      :zoom="1"
      :scroll-zoom="false"
      @load="onMapLoad"
    />
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl';
import { MglMap } from 'vue-mapbox';

export default {
  name: 'Map',
  components: {
    MglMap,
  },

  data() {
    return {
      accessToken: 'pk.eyJ1IjoidGlmYWluZWsiLCJhIjoiY2tvY3k4OW1pMG1nNjJvbjFwcnNzNWI4eiJ9.4GbB9581EIhx7AA43BpmTg', // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/tifainek/ckor6l9ku14w217q9l71uvj2y', // your map style
      citiesCoord: [
        {
          // Toulouse
          lat: 43.6043,
          long: 1.4437,
        },
        {
          // Paris
          lat: 48.8534,
          long: 2.3488,
        },
        {
          // Bordeaux
          lat: 44.8333,
          long: -0.5667,
        },
        {
          // Lyon
          lat: 45.764043,
          long: 4.835659,
        },
        {
          // Tours
          lat: 47.394144,
          long: 0.68484,
        },
      ],
    };
  },

  mounted() {
    this.mapbox = Mapbox;
  },

  methods: {
    onMapLoad({ map }) {
      map.addControl(new Mapbox.NavigationControl());
      map.flyTo({
        center: [2.591, 46.788],
        zoom: 5,
        bearing: 2,
        speed: 2,
        easing(t) {
          return t;
        },
        essential: true,
      });

      for (let i = 0; i < this.citiesCoord.length; i += 1) {
        new Mapbox.Marker({ color: '#118AB2' })
          .setLngLat([this.citiesCoord[i].long, this.citiesCoord[i].lat])
          .addTo(map);
      }
    },
  },
};
</script>

<style scoped lang="scss">
 .map {
   height: 500px;
   width: 100%;
 }

 .title {
    width: 600px;
    line-height: 55px;
    text-align: center;
    font-size: 45px;
    padding-bottom: 40px;
  }

  .map-section {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: $mobileBreakpoint) {
    .title {
      width: 270px;
      line-height: 30px;
      font-size: 28px;
    }

    .map {
      height: 300px;
    }

    .map-section {
      margin-top: 50px;
    }
  }
</style>
