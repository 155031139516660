<template>
  <div class="input-container">
    <label>{{ label }}</label>
    <mapbox-geocoder
      id="adress"
      :access-token="accessToken"
      :types="types"
      :limit="4"
      :placeholder="defaultValue"
      @mb-created="instance => control = instance"
      @mb-result="res => {handleSearch(res)}"
    />
  </div>
</template>

<script>
import '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';

export default {
  name: 'Geocoder',

  props: {
    label: {
      required: true,
      type: String,
    },
    returnDataFunction: {
      required: true,
      type: Function,
    },
    defaultValue: {
      default: '',
      type: String,
    },
    types: {
      default: 'address',
      type: String,
    },
  },

  data() {
    return {
      accessToken: 'pk.eyJ1IjoidGlmYWluZWsiLCJhIjoiY2tvY3k4OW1pMG1nNjJvbjFwcnNzNWI4eiJ9.4GbB9581EIhx7AA43BpmTg',
    };
  },

  methods: {
    handleSearch(event) {
      this.returnDataFunction(event.result);
    },
  },
};
</script>

<style lang="scss">
.input-container {
  display: flex;
  flex-direction: column;
  height: 75px;
  width: 320px;
}

label {
  font-size: 12px;
  color: #4F4F4F;
}

.mapboxgl-ctrl-geocoder {
  font-family: $textFont;
  box-shadow: none;
  min-width: unset;

  .suggestions-wrapper {
    .suggestions {
      width: 320px;
    }
  }
}

.mapboxgl-ctrl-geocoder--input {
  margin-top: 5px;
  height: 42px;
  width: 322px;
  border: 1px solid white;
  border-radius: 6px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.10);
  padding: 0 10px;
  font-family: $textFont;
}

.mapboxgl-ctrl-geocoder--input::placeholder {
  color: $titleColor;
}

.mapboxgl-ctrl-geocoder--input:focus {
  border: 2px solid $primary;
  outline: none;
}

.mapboxgl-ctrl-geocoder--icon {
  display: none;
}

@media (max-width: $mobileBreakpoint) {
  .input-container {
    display: flex;
    flex-direction: column;
    height: 75px;
    width: 220px;
  }

  .mapboxgl-ctrl-geocoder--input {
    margin-top: 5px;
    height: 42px;
    width: 220px;
    border: 1px solid white;
    border-radius: 6px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.10);
    padding: 0 10px;
    font-family: $textFont;
  }

  .mapboxgl-ctrl-geocoder {
    .suggestions-wrapper {
      .suggestions {
        width: 220px;
      }
    }
  }
}
</style>
