<template>
  <div class="homepage-title">
    <div class="left-part">
      <div class="left-container">
        <h3 class="subtitle">
          {{ $t('homepage.title.subtitle') }}
        </h3>
        <h1 class="title">
          <span class="thin-title">{{ $t('homepage.title.title1') }}</span>
          <span>{{ $t('homepage.title.title2') }}</span>
          <span class="thin-title">{{ $t('homepage.title.title3') }}</span>
          <span>{{ $t('homepage.title.title4') }}</span>
        </h1>
        <StoreButtons />
      </div>
    </div>
    <div class="right-part">
      <img
        src="../../assets/homepage-image.png"
        class="homepage-app"
        alt="homepage-app-image"
      >
    </div>
  </div>
</template>

<script>
import StoreButtons from '../Cards/StoreButtons.vue';

export default {
  name: 'HomepageTitle',
  components: { StoreButtons },
};
</script>

<style lang='scss' scoped>
  .homepage-title {
    display: flex;
    height: 680px;
  }

  .left-part {
    background-color: rgba(17, 138, 178, 0.3);
    height: 100%;
    flex: 1;
  }

  .left-container {
    padding: 20% 0 0 20%;
  }

  .right-part {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    height: 100%;
    flex: 1;
  }

  .title {
    display: flex;
    flex-direction: column;
    font-size: 60px;
    line-height: 60px;
    padding-bottom: 50px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
  }

  .thin-title {
    font-weight: 400;
  }

  .homepage-app {
    width: 490px;
  }

  @media (max-width: 990px) and (min-width: 600px) {
    .homepage-app {
      width: 250px;
    }
    .title {
      display: flex;
      flex-direction: column;
      font-size: 30px;
      line-height: 30px;
      padding-bottom: 10px;
    }

    .subtitle {
      font-size: 14px;
    }

    .left-part {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .left-container {
      padding: 0;
    }
  }

  @media (max-width: 600px) {
    .homepage-title {
      flex-direction: column;
      height: 600px;
    }

    .left-part {
      height: 50%;
      width: 100%;
    }

    .left-container {
      padding: 15% 0 0 50px;
    }

    .right-part {
      height: 50%;
      width: 100%;
    }

    .title {
      display: flex;
      flex-direction: column;
      font-size: 30px;
      line-height: 30px;
      padding-bottom: 10px;
    }

    .subtitle {
      font-size: 14px;
    }

    .homepage-app {
      position: relative;
      top: 0px;
      min-width: 100px;
      max-width: 250px;
    }
  }
</style>
