<template>
  <div class="pro-benefit">
    <div
      class="icon background-color"
      :class="pictoColor"
    >
      <img
        src="../assets/icons/check.svg"
        alt="check"
      >
    </div>
    <p class="text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ProBenefit',

  props: {
    text: {
      type: String,
      required: true,
    },
    pictoColor: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
  .pro-benefit {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  .icon {
    background-color: red;
    padding: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 8px;
  }

  .text {
    color: black;
    font-weight: 600;
  }

  .background-color.yellow {
    background-color: $secondary1;
  }

  .background-color.purple {
    background-color: $secondary2;
  }

  .background-color.pink {
    background-color: $secondary3;
  }

  @media (max-width: $mobileBreakpoint) {
    .pro-benefit {
      flex-direction: column;
      margin-right: 0;
    }
  }
</style>
