<template>
  <div class="signin">
    <h1 class="title-modal">
      {{ $t('modal.login.title') }}
    </h1>
    <p class="subtitle">
      {{ $t('modal.login.subtitle') }}
    </p>
    <Input
      v-model="email"
      type="text"
      :label="this.$t('modal.login.form.email')"
    />
    <Input
      v-model="password"
      type="password"
      :label="this.$t('modal.login.form.password')"
    />
    <CustomButton
      class="button"
      @click.native="submit()"
    >
      {{ $t('modal.login.form.submit') }}
    </CustomButton>
    <span class="error-text">{{ errorMessage }}</span>
    <span
      class="signin-link"
      @click.prevent="redirectFunction"
    >
      {{ $t('modal.login.form.register') }}
    </span>
  </div>
</template>

<script>
import Input from '../Forms/Input.vue';
import CustomButton from '../Buttons/CustomButton.vue';
import login from '../../services/Security/login';

export default {
  name: 'Login',
  components: { Input, CustomButton },

  props: {
    redirectFunction: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      password: '',
      email: '',
      errorMessage: '',
    };
  },

  methods: {
    async submit() {
      this.errorMessage = '';

      if (this.password === '' || this.email === '') {
        this.errorMessage = this.$t('form.error.mandatoryFields');
        return;
      }
      if (!this.email.match(/[a-z0-9._%+!$&*=^|~#%{}/-]+@([a-z0-9-]+.){1,}([a-z]{2,22})/g)) {
        this.errorMessage = this.$t('form.error.invalidEmail');
        return;
      }

      const loginRes = await login(this.email, this.password);
      if (loginRes.noResponse) {
        this.errorMessage = this.$t('form.error.error');
        return;
      }
      if (loginRes.error) {
        if (loginRes.error.data.message === 'Invalid credentials.') this.errorMessage = this.$t('form.error.nonexistentIdentifier');
        else this.errorMessage = this.$t('form.error.error');
        return;
      }
      await this.$router.push('/welcome');
    },
  },
};
</script>

<style lang="scss" scoped>
  .title-modal {
    text-align: center;
  }

  .button {
    margin-top: 20px;
    width: 80%;
  }

  .signin {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subtitle {
    margin: 0 0 40px 0!important;
    width: 400px;
    text-align: center;
  }

  .signin-link {
    text-decoration: underline;
    cursor: pointer;
    margin-top: 20px;
    font-size: 14px;
  }

  .error-text {
    font-size: 12px;
    color: red;
    padding-top: 20px;
    text-align: center;
    width: 100%;
    display: block;
  }

  @media (max-width: $mobileBreakpoint) {
    .title-modal {
      font-size: 30px;
    }

    .subtitle {
      width: 240px;
      font-size: 14px;
    }
  }
</style>
