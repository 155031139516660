const axios = require('axios');

export default async function login(email, password) {
  const body = {
    username: email,
    password,
  };
  const res = await axios.post('security/login', body)
    .then((response) => ({ user: response.data }))
    .catch((e) => ({ error: e.response, noResponse: e.request }));

  return res;
}
