<template>
  <div class="concept-section">
    <h1 class="title">
      {{ $t('homepage.concept.title') }}
    </h1>
    <p class="subtitle">
      {{ $t('homepage.concept.subtitle') }}
    </p>
    <div
      class="concept-cards"
      data-aos="fade-zoom-in"
    >
      <ConceptCard
        v-for="(cardContent) in cardsContent"
        :key="cardContent.title"
        :picto="cardContent.picto"
        :background-color="cardContent.color"
      >
        <template #title>
          {{ cardContent.title }}
        </template>
        <template #description>
          {{ cardContent.description }}
        </template>
      </ConceptCard>
    </div>
    <StoreButtons />
  </div>
</template>

<script>
import ConceptCard from '../Cards/ConceptCard.vue';
import StoreButtons from '../Cards/StoreButtons.vue';
import searchPicto from '../../assets/icons/search.svg';
import happyPicto from '../../assets/icons/happy.svg';
import cheersPicto from '../../assets/icons/cheers.svg';

export default {
  name: 'ConceptSection',
  components: { ConceptCard, StoreButtons },

  data() {
    return {
      cardsContent: [
        {
          title: this.$t('homepage.concept.cards.search.title'),
          description: this.$t('homepage.concept.cards.search.description'),
          picto: searchPicto,
          color: 'yellow',
        },
        {
          title: this.$t('homepage.concept.cards.choose.title'),
          description: this.$t('homepage.concept.cards.choose.description'),
          picto: cheersPicto,
          color: 'pink',
        },
        {
          title: this.$t('homepage.concept.cards.haveFun.title'),
          description: this.$t('homepage.concept.cards.haveFun.description'),
          picto: happyPicto,
          color: 'purple',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
  .concept-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 100px 0 100px;
  }

  .title {
    width: 500px;
    line-height: 55px;
    text-align: center;
    font-size: 45px;
  }

  .subtitle {
    width: 600px;
    text-align: center;
    margin-bottom: 40px;
  }

  .concept-cards {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }

  @media (max-width: $mobileBreakpoint) {
    .concept-section {
      padding: 50px 40px 0 40px;
    }

    .title {
      width: 270px;
      line-height: 30px;
      font-size: 28px;
    }

    .subtitle {
      width: 320px;
    }

    .concept-cards {
      flex-direction: column;
    }
  }
</style>
