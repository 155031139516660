<template>
  <button
    class="custom-button"
    :class="{primary}"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'CustomButton',

  props: {
    primary: {
      default: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
  .custom-button {
    color: white;
    border: none;
    border-color: none;
    border-radius: 12px;
    outline: 0;
    font-family: $titleFont;
    font-size: 16px;
    line-height: 15px;
    cursor: pointer;
    padding: 15px;
  }

  .primary {
    background-color: $primary;
  }

  @media (max-width: $mobileBreakpoint) {
    .custom-button {
      font-size: 14px;
    }
  }

</style>
