<template>
  <div class="claim-step1">
    <h1 class="title-modal">
      {{ $t('claimModal.title') }}
    </h1>
    <p
      class="subtitle"
    >
      {{ $t('claimModal.step1.subtitle') }}
    </p>
    <Geocoder
      :label="$t('claimModal.step1.cityLabel')"
      :return-data-function="getGeocoderData"
      :default-value="geocoderDefaultValue"
      types="place"
    />
    <Autocomplete
      :label="$t('claimModal.step1.label')"
      :search-data="searchData"
      :return-data-function="getAutocompleteData"
      :default-input-content="autocompleteData"
      :disabled="isInputDisabled"
      class="step1-autocomplete"
    />
    <span
      class="text-link"
      @click.prevent="goToStep2()"
    >{{ $t('claimModal.step1.createEstablishment') }}</span>
    <CustomButton
      class="button"
      @click.native="checkValidateButton()"
    >
      {{ $t('claimModal.validate') }}
    </CustomButton>
  </div>
</template>

<script>
import CustomButton from '../Buttons/CustomButton.vue';
import Autocomplete from '../Forms/Autocomplete.vue';
import Store from '../../store/index';
import getNotClaimedEstablishments from '../../services/Claim/getNotClaimed';
import Geocoder from '../Forms/Geocoder.vue';

export default {
  name: 'ClaimStep1',
  components: { Autocomplete, CustomButton, Geocoder },

  props: {
    goToStep2: {
      type: Function,
      required: true,
    },
    goToStep3: {
      type: Function,
      required: true,
    },
    setError: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      model: null,
      autocompleteData: Store.state.establishment.name,
      searchData: [],
      city: '',
    };
  },

  computed: {
    isInputDisabled() {
      return !this.city || (!Store.state.establishment.name && !Store.state.establishment.city);
    },

    geocoderDefaultValue() {
      return Store.state.establishment.city;
    },
  },

  methods: {
    getAutocompleteData(data) {
      this.autocompleteData = data;
    },

    checkValidateButton() {
      this.setError('');
      if (!this.autocompleteData) {
        this.setError(this.$t('form.error.mandatoryFields'));
        return;
      }
      const establishmentData = this.searchData.find((elem) => elem.name === this.autocompleteData);
      Store.commit('updateEstablishmentStep1', { name: this.autocompleteData, id: establishmentData.id });
      this.goToStep3();
    },

    async getGeocoderData(data) {
      this.city = data.text_fr;
      if (this.city) {
        await Store.commit('updateCityStep1', { city: this.city });
        const res = await getNotClaimedEstablishments(this.city.toLowerCase());
        this.searchData = res.success || [];
      }
    },
  },
};
</script>

<style src="vue-advanced-search/dist/AdvancedSearch.css"></style>
<style lang="scss" scoped>
  .title-modal {
    text-align: center;
  }

  .button {
    width: 70%;
  }

  .claim-step1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subtitle {
    margin: 0 0 40px 0!important;
    width: 400px;
    text-align: center;
  }

  .text-link {
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 25px;
  }

  .step1-autocomplete {
    margin-bottom: 15px;
    position: relative;
  }

  @media (max-width: $mobileBreakpoint) {
    .title-modal {
      line-height: 1.2;
      font-size: 30px;
    }

    .subtitle {
      font-size: 14px;
      margin-top: 5px;
      width: 240px;
    }
  }
</style>
