<template>
  <div class="homepage">
    <HomepageHeader />
    <HomepageTitle />
    <ConceptSection />
    <Map />
    <MoodsSection />
    <DownloadAppSection>
      {{ $t('homepage.download.subtitle1') }}
      <br>
      {{ $t('homepage.download.subtitle2') }}
    </DownloadAppSection>
    <JoinSection />
    <Footer />
  </div>
</template>

<script>
import HomepageHeader from '../components/Sections/HomepageHeader.vue';
import HomepageTitle from '../components/Sections/HomepageTitle.vue';
import ConceptSection from '../components/Sections/ConceptSection.vue';
import Footer from '../components/Sections/Footer.vue';
import DownloadAppSection from '../components/Sections/DownloadAppSection.vue';
import JoinSection from '../components/Sections/JoinSection.vue';
import MoodsSection from '../components/Sections/MoodsSection.vue';
import Map from '../components/Sections/Map.vue';

export default {
  name: 'HomePage',
  components: {
    HomepageHeader,
    HomepageTitle,
    ConceptSection,
    Footer,
    DownloadAppSection,
    JoinSection,
    MoodsSection,
    Map,
  },
};
</script>

<style lang="scss" scoped>
 .homepage {
 }

  .map {
    width: 100%;
    height: 500px;
  }
</style>
