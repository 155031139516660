<template>
  <div class="claim-step2">
    <h1 class="title-modal">
      {{ $t('claimModal.title') }}
    </h1>
    <p
      class="subtitle"
    >
      {{ $t('claimModal.step2.subtitle') }}
    </p>
    <Input
      v-model="establishment.name"
      :label="$t('claimModal.step2.nameLabel')"
      type="text"
      :input-value="establishment.name"
    />
    <Geocoder
      :label="$t('claimModal.step2.adressLabel')"
      :return-data-function="getGeocoderData"
      :default-value="establishment.address.place_name || $t('form.placeholders.geocoder')"
    />
    <CustomButton
      class="button"
      @click.native="checkValidateButton()"
    >
      {{ $t('claimModal.validate') }}
    </CustomButton>
  </div>
</template>

<script>
import Input from '../Forms/Input.vue';
import CustomButton from '../Buttons/CustomButton.vue';
import Geocoder from '../Forms/Geocoder.vue';
import Store from '../../store/index';
import createEstablishment from '../../services/Claim/createEstablishment';

export default {
  name: 'ClaimStep2',
  components: { Input, CustomButton, Geocoder },

  props: {
    goToNextStep: {
      type: Function,
      required: true,
    },
    setError: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      establishment: {
        name: Store.state.establishment.name,
        address: Store.state.establishment.address,
        id: '',
      },
    };
  },

  methods: {
    async checkValidateButton() {
      this.setError('');
      if (!this.establishment.name || !this.establishment.address) {
        this.setError(this.$t('form.error.mandatoryFields'));
        return;
      }
      const addressData = this.establishment.address;
      const establishment = await createEstablishment({
        name: this.establishment.name,
        type: Store.state.establishment.type,
        description: 'Mon super établissement',
        coordinate: {
          areaCode: '33',
          phoneNumber: '0666666666',
          address: {
            number: addressData.address,
            street: addressData.text_fr,
            city: this.getCityFromAddress(addressData).text_fr,
            postcode: this.getPostcodeFromAddress(addressData).text_fr,
            country: 'FRA',
          },
          location: {
            longitude: addressData.center[0],
            latitude: addressData.center[1],
          },
        },
      });
      if (establishment.error) {
        this.setError(this.$t('form.error.error'));
        return;
      }
      this.establishment.id = establishment.success.id;
      Store.commit('updateEstablishmentStep2', this.establishment);
      this.goToNextStep();
    },

    getPostcodeFromAddress(address) {
      return address.context.find((elem) => elem.id.includes('postcode')) || {};
    },

    getCityFromAddress(address) {
      return address.context.find((elem) => elem.id.includes('place')) || {};
    },

    getCountryFromAddress(address) {
      return address.context.find((elem) => elem.id.includes('country')) || {};
    },

    getGeocoderData(data) {
      this.establishment.address = data;
    },
  },
};
</script>

<style lang="scss" scoped>
  .title-modal {
    text-align: center;
  }

  .button {
    width: 70%;
    margin-top: 20px;
  }

  .claim-step2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subtitle {
    margin: 0 0 40px 0!important;
    width: 400px;
    text-align: center;
  }

  @media (max-width: $mobileBreakpoint) {
    .title-modal {
      line-height: 1.2;
      font-size: 30px;
    }

    .subtitle {
      font-size: 14px;
      margin-top: 5px;
      width: 240px;
    }
  }
</style>
