<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'App',
  data: () => ({
    supportedLanguages: ['en', 'fr'],
  }),
  created() {
    if (localStorage.getItem('locale')) {
      this.$i18n.locale = localStorage.getItem('locale');
    } else if (this.supportedLanguages.includes(navigator.language)) {
      this.$i18n.locale = navigator.language.substring(0, 2);
    } else {
      localStorage.setItem('locale', this.$i18n.fallbackLocale);
    }
  },
};
</script>

<style lang="scss">
#app {
  font-family: $textFont;
  color: $textColor;
  width: 100%;
  overflow: hidden;
  font-display: swap;

  h1,
  h2,
  h3,
  h4 {
    font-family: $titleFont;
    color: $titleColor;
    margin: 0;
  }
}
</style>
