<template>
  <div class="step2">
    <h1 class="title-modal">
      {{ $t('modal.register.step2.title') }}
    </h1>
    <p
      v-if="userType === 'pro'"
      class="subtitle"
    >
      {{ $t('modal.register.step2.subtitleProfessional') }}
    </p>
    <p
      v-if="userType === 'priv'"
      class="subtitle"
    >
      {{ $t('modal.register.step2.subtitlePrivate') }}
    </p>
    <Input
      v-model="email"
      type="text"
      :label="this.$t('modal.register.step2.form.email')"
      :input-value="email"
    />
    <Input
      v-model="password"
      type="password"
      :label="this.$t('modal.register.step2.form.password')"
      :input-value="password"
    />
    <CustomButton
      class="button"
      @click.native="validateForm()"
    >
      {{ $t('modal.register.step2.form.submit') }}
    </CustomButton>
  </div>
</template>

<script>
import Input from '../Forms/Input.vue';
import CustomButton from '../Buttons/CustomButton.vue';

export default {
  name: 'Step2',
  components: { Input, CustomButton },

  props: {
    userType: {
      required: true,
      type: String,
    },
    setError: {
      type: Function,
      default: () => {},
    },
    userEmail: {
      type: String,
      required: true,
    },
    userPassword: {
      type: String,
      required: true,
    },
    addDataFunction: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      password: '',
      email: '',
    };
  },

  mounted() {
    this.password = this.userPassword;
    this.email = this.userEmail;
  },

  methods: {
    validateForm() {
      this.setError('');

      if (this.password === '' || this.email === '') {
        this.setError(this.$t('form.error.mandatoryFields'));
        return;
      }
      if (!this.email.match(/[a-z0-9._%+!$&*=^|~#%{}/-]+@([a-z0-9-]+.){1,}([a-z]{2,22})/g)) {
        this.setError(this.$t('form.error.invalidEmail'));
        return;
      }
      if (this.password.length < 8) {
        this.setError(this.$t('form.error.passwordLength'));
        return;
      }

      this.addDataFunction(this.email, this.password);
    },
  },
};
</script>

<style lang="scss" scoped>
  .title-modal {
    text-align: center;
  }

  .button {
    margin-top: 20px;
    width: 80%;
  }

  .step2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subtitle {
    margin: 0 0 40px 0!important;
    width: 400px;
    text-align: center;
  }

  @media (max-width: $mobileBreakpoint) {
    .title-modal {
      font-size: 30px;
    }

    .subtitle {
      font-size: 14px;
      width: 240px;
    }
  }
</style>
