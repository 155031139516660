const axios = require('axios');

export default async function register(userData) {
  const body = {
    name: userData.name,
    email: userData.email,
    password: userData.password,
  };

  if (userData.ageRange) {
    body.ageRange = userData.ageRange;
  }

  if (userData.birthDate) {
    body.birthDate = userData.birthDate;
  }

  const res = await axios.post('security/register', body)
    .then((response) => ({ user: response.data }))
    .catch((e) => ({ error: e.response }));

  return res;
}
