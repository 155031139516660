<template>
  <div>
    <img
      src="../../assets/google-play-badge.webp"
      class="play-store-button"
      alt="play-store-button"
    >
  </div>
</template>

<script>
export default {
  name: 'PlayStoreButton',
};
</script>

<style lang="scss" scoped>
 .play-store-button {
   width: 200px;
  @media (max-width: $mobileBreakpoint) {
    width: 140px;
  }
 }
</style>
