<template>
  <div class="claim-step2">
    <h1 class="title-modal">
      {{ $t('claimModal.step4.title') }}
    </h1>
    <p
      class="subtitle"
    >
      {{ $t('claimModal.step4.subtitle1') }}
      {{ establishmentName }}
      {{ $t('claimModal.step4.subtitle2') }}
    </p>
    <CustomButton
      class="button"
      @click.native="close()"
    >
      {{ $t('claimModal.validate') }}
    </CustomButton>
  </div>
</template>

<script>
import CustomButton from '../Buttons/CustomButton.vue';
import Store from '../../store/index';

export default {
  name: 'ClaimStep2',
  components: { CustomButton },

  props: {
    close: {
      type: Function,
      required: true,
    },
  },

  computed: {
    establishmentName() {
      return Store.state.establishment.name;
    },
  },
};
</script>

<style lang="scss" scoped>
  .title-modal {
    text-align: center;
  }

  .button {
    width: 70%;
    margin-top: 20px;
  }

  .claim-step2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .subtitle {
    margin: 30px 0 20px 0!important;
    width: 400px;
    text-align: center;
  }

  @media (max-width: $mobileBreakpoint) {
    .title-modal {
      line-height: 1.2;
      font-size: 30px;
    }

    .subtitle {
      font-size: 14px;
      margin-top: 5px;
      width: 240px;
    }
  }
</style>
